import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/authContext'
import { doSignOut } from '../../firebase/auth'

const Logout = () => {
    const navigate = useNavigate()
    const { userLoggedIn } = useAuth()
    if(userLoggedIn) {
        doSignOut().then(() => {navigate("/")});
    }
    return (
        <></>
    )
}

export default Logout