import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/Home';
import reportWebVitals from './reportWebVitals';
import Navbar from './features/Navbar';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import FourOhFour from './pages/404';
import Footer from './features/Footer';
import Artists from './pages/artists/Artists';
import ContactUs from './pages/ContactUs';
import News from './pages/News';
import Services from './pages/Services';
import Shows from './pages/Shows';
import Venues from './pages/Venues';
import SpecificArtists from './pages/artists/SpecificArtists';
import { AuthProvider } from './contexts/authContext';
import Login from './pages/auth/Login.jsx';
import Register from './pages/auth/Register';
import Logout from './pages/auth/Logout.jsx';
import PrivateRoute from './firebase/PrivateRoute.jsx';
import Dashboard from './pages/Dashboard.jsx';
import User from './pages/User.jsx';
import { UserProvider } from './contexts/userContext/index.jsx';
import Users from './pages/Users.jsx';
import MobileNavbar from './features/MobileNavbar.js';

const router = createBrowserRouter([{
  errorElement: <FourOhFour />,
  children: [
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/artists',
      element: <Artists />
    },
    {
      path: '/artists/:id',
      element: <SpecificArtists />
    },
    {
      path: '/contactus',
      element: <ContactUs />
    },
    {
      path: '/news',
      element: <News />
    },
    {
      path: '/services',
      element: <Services />
    },
    {
      path: '/shows',
      element: <Shows />
    },
    {
      path: '/venues',
      element: <Venues />
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/register',
      element: <Register />
    },
    {
      path: '/logout',
      element: <Logout />
    },
    {
      path: '/dashboard',
      element: <PrivateRoute><Dashboard /></PrivateRoute>
    },
    {
      path: '/u/:id',
      element: <User/>
    },
    {
      path: '/u/:id/edit',
      element: <User edit/>
    },
    {
      path: '/users',
      element: <Users />
    },
  ]
}]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <UserProvider>
      <React.StrictMode>
        <div className="fixed-border"></div>
        <div className="content">
          <Navbar />
          <RouterProvider router={router} />
          <Footer />
          <MobileNavbar />
        </div>
      </React.StrictMode>
    </UserProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
