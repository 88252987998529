import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/authContext';
import { getProfile } from '../firebase/Profile';
import { Username } from '../components/profile/Username';
//import { getDoc, doc } from "firebase/firestore";
//import { db } from './firebase'; // Initialize Firestore in firebase.js

const Dashboard = () => {
  const [profile, setProfile] = useState(null);
  const { currentUser } = useAuth();

  function getDate(profileDate) {
    let date = new Date(profileDate*1);
    return date.getMonth() + "/" + date.getDate() + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }

  useEffect(() => {
    const fetchProfile = async () => {
      if (currentUser) {
        //const profileDoc = await getDoc(doc(db, "users", currentUser.uid));
        setProfile(currentUser);
      }
    };

    fetchProfile();
  }, [currentUser]); // Include currentUser in the dependency array

  if (!profile) return <p>Loading...</p>;

  return (
    <>
        <p><u>Username:</u> <Username /></p>
        <p><u>Profile Name:</u> {getProfile().displayName}</p>
        <p><u>Email:</u> {profile.email}</p>
        <p><u>Account Created at:</u> {getDate(profile.metadata.createdAt)}</p>
        <p><u>Last login:</u> {getDate(profile.metadata.lastLoginAt)}</p>
        <p>
            {JSON.stringify(profile, null, 2)}
        </p>
    </>
  );
};

export default Dashboard;