import React, { useEffect } from 'react';
import { useUser } from '../../contexts/userContext';

const Username = (props) => {
    const { userData, loading } = useUser();

    useEffect(() => {
      if (loading) {
        console.log('Loading user data...');
      } else if (userData) {
        console.log('User data loaded:', userData);
      }
    }, [userData, loading]);

  //if (error) return <p>Error: {error}</p>;
  //if (!username) return <p>Loading...</p>;

  return <>{userData && userData.username}</>;
};

const UsernameLink = () => {
    const { userData } = useUser();

    /*useEffect(() => {
      if (loading) {
        console.log('Loading user data...');
      } else if (userData) {
        console.log('User data loaded:', userData);
      }
    }, [userData, loading]);*/

  //if (error) return <p>Error: {error}</p>;
  //if (!username) return <p>Loading...</p>;

  return <a href={userData && "/u/" + userData.username}>{userData && "/u/" + userData.username}</a>;
}

export { Username, UsernameLink };
