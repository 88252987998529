import '../assets/App.css';
import contactus from '../assets/navbar/contactus.png'
import '../assets/stylesheets/Footer.css';

function Footer() {
  return (
    <div className="footer">
      <hr />
      <p>Made with ♥ by folks who love music. Feel free to <a href="/contactus"><img alt='Contact Us Link' src={contactus} /></a></p>
    </div>
  );
}

export default Footer;
