import { useEffect, useState } from "react";
import ExtractedContent from "../features/ExtractedContent";
import { getAPIURL } from "../network";
import '../assets/stylesheets/Shows.css'
const artists = require('../assets/data/artists.json');

function convertToDateWithYear(dateStr, year) {
  const [month, day] = dateStr.split('/').map(Number);
  return new Date(year, month - 1, day);
}

function Shows() {
  
  const [listings, setListings] = useState([]);

  function parseDateAndTime(date, time) {
    const [month, day] = date.split('/').map(Number);
    const year = new Date().getFullYear(); // Assuming events are in the current year
    const hours = parseInt(time.slice(0, 2), 10); // Extract hours
    const minutes = parseInt(time.slice(2), 10); // Extract minutes
    return new Date(year, month - 1, day, hours, minutes); // Create a Date object
  }

  function getDateFormatted(listing) {
    let startTime = parseDateAndTime(listing.date, listing.startTime);
    let endTime = null;
    if(listing.endTime) {
      endTime = " - " + parseDateAndTime(listing.date, listing.endTime).toLocaleString('en-US', {
        hour: 'numeric', // Display hours in two digits (e.g., 06 for 6am)
        minute: '2-digit', // Display minutes in two digits
        hour12: true // Use 12-hour clock
      });
    }
    return listing.date + " @ " + startTime.toLocaleString('en-US', {
      hour: 'numeric', // Display hours in two digits (e.g., 06 for 6am)
      minute: '2-digit', // Display minutes in two digits
      hour12: true // Use 12-hour clock
    }) + (endTime ? endTime : '');
  }

  const ArtistLink = (props) => {
    const { name } = props;
  
    for (const [artistname, artist] of Object.entries(artists)) {
      if (name.toLowerCase().trim() === artist.name.toLowerCase().trim()) {
        return <a href={`/artists/${artistname}`}>{name}</a>;
      }
      
      if(artist.othernames) {
        for (const othername of artist.othernames) {
          if (othername.toLowerCase().trim() === name.toLowerCase().trim()) {
            return <a href={`/artists/${artistname}`}>{name}</a>;
          }
        }
      }
    }
  
    return name;
  };

  const Listings = ({ listings }) => {
    const groupedListings = listings.reduce((acc, listing) => {
      // Group listings by date
      if (!acc[listing.date]) {
        acc[listing.date] = [];
      }
      acc[listing.date].push(listing);
      return acc;
    }, {});
  
    return (
      <div>
        {Object.keys(groupedListings).map((date) => (
          <>
            <center><h1>{parseDateAndTime(date, "0000").toLocaleDateString('en-US', { weekday: 'long' })} {date}</h1></center>
            <div key={date} className="listings">
              {groupedListings[date].map((listing, index) => (
                <div key={index} className="listing">
                  <center><h3><ArtistLink name={listing.performer || listing.event} /></h3></center>
                  <p>📍 {listing.venue}</p>
                  <span>📅 {getDateFormatted(listing)}</span>
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
    );
  };

  const EListings = () => {

    <div className="listings">
        {listings.map((listing, index) => (
          <div key={index} className="listing">
            <h3>{listing.venue}</h3>
            <p>{listing.performer || listing.event}</p>
            <span>{ getDateFormatted(listing) }</span>
          </div>
        ))}
      </div>
  }

  useEffect(() => {
    async function fetchContent() {
      try {
        const response = await fetch(getAPIURL());
        const fetchedListings = await response.json();
        //console.log(fetchedListings)
        const newListings = fetchedListings.listings;
        console.log(newListings)
        newListings.sort((a, b) => {
          const dateTimeA = parseDateAndTime(a.date, a.startTime || "0000"); // Default to midnight if no time
          const dateTimeB = parseDateAndTime(b.date, b.startTime || "0000");
          return dateTimeA - dateTimeB; // Sort in ascending order
        });
        setListings(newListings);
      } catch {

      }
    }
    fetchContent();
  },[]);

  return (
    <div className="page-content">
      <h1>Shows</h1>
      <p>All of the content on this page was graciously provided by Elegant Island Living's Blog <a href="https://www.elegantislandliving.net/blogs/im-with-the-band/Golden-Isles-Brunswick-Weekly-Live-Music/">"I'm With The Band"</a>, written by Kathi Williams, as per the terms at the bottom of their page. Thanks for all you do, Kathi!</p>
      <p>If you feel so compelled to and that this service is of value to you, please support them!</p>
      { /*<ExtractedContent />*/ }
      { /*JSON.stringify(listings)*/ }
      <Listings listings={listings} />
    </div>
  );
}

export default Shows;
