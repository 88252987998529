import '../assets/stylesheets/Navbar.css';
import logo from '../assets/navbar/logo.png'
import artists from '../assets/navbar/artists.png';
import contactus from '../assets/navbar/contactus.png'
import news from '../assets/navbar/news.png'
import shows from '../assets/navbar/shows.png'
import venues from '../assets/navbar/venues.png'
import { useAuth } from '../contexts/authContext';
import { UsernameLink } from '../components/profile/Username';

function Navbar() {
  const { userLoggedIn } = useAuth();
  return (
    <div className="navbar">
      <div className='search-border'>
        <a href="/"><img alt='912Jams Logo' className='nav-logo' src={logo} /></a>
        <input className='search-box' placeholder='Search 912Jams'/>
      </div>
      <div className='nav-img-container'>
        <a href="/artists"><img alt='Artists Link' className='nav-img-link' src={artists} /></a>
        <a href="/contactus"><img alt='Contact Us Link' className='nav-img-link' src={contactus} /></a>
        <a href="/news"><img alt='News Link' className='nav-img-link' src={news} /></a>
        {/*<a href="/services"><img className='nav-img-link' src={services} /></a>*/}
        <a href="/shows"><img alt='Shows Link' className='nav-img-link' src={shows} /></a>
        <a href="/venues"><img alt='Venues Link' className='nav-img-link' src={venues} /></a>
      </div>
      <p>
        {/*userLoggedIn ? (
          <>
            Logged In as <UsernameLink /> | <a href='/logout'>Sign Out</a> | <a href='/users'>User Directory</a>
          </>
        ) : (
          <>
            <a href='/register'>Sign Up</a> | <a href='/login'>Login</a> | <a href='/users'>User Directory</a>
          </>
        )*/}
      </p>
    </div>
  );
}

export default Navbar;
