import '../assets/stylesheets/MobileNavbar.css';
import { FaBeer, FaRegNewspaper, FaRegUser } from "react-icons/fa";
import { HiOutlineUserGroup } from "react-icons/hi";
import { TbBuildingCircus } from "react-icons/tb";
import { IoTicketOutline } from "react-icons/io5";

function MobileNavbar() {

  return (
    <div className="mobile-navbar">
      <div onClick={() => {window.location.href = '/shows'}} className='mobile-navbar-button-div'><IoTicketOutline /><p>Shows</p></div>
      <div onClick={() => {window.location.href = '/artists'}} className='mobile-navbar-button-div'><HiOutlineUserGroup /><p>Artists</p></div>
      <div onClick={() => {window.location.href = '/news'}} className='mobile-navbar-button-div'><FaRegNewspaper /><p>News</p></div>
      <div onClick={() => {window.location.href = '/venues'}} className='mobile-navbar-button-div'><TbBuildingCircus /><p>Venues</p></div>
      <div onClick={() => {window.location.href = '/profile'}} className='mobile-navbar-button-div'><FaRegUser /><p>Profile</p></div>
    </div>
  );
}

export default MobileNavbar;