import { Navigate, useParams } from "react-router-dom";
import { getProfileByUsername, updateProfile } from "../firebase/Profile";
import { useCallback, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { BtnBold, BtnBulletList, BtnClearFormatting, BtnItalic, BtnLink, BtnNumberedList, BtnRedo, BtnStrikeThrough, BtnStyles, BtnUnderline, BtnUndo, Editor, EditorProvider, HtmlButton, Separator, Toolbar } from "react-simple-wysiwyg";

const User = (props) => {
    const { id } = useParams();
    const { currentUser } = getAuth();

    const [bio, setBio] = useState('');
    const [location, setLocation] = useState('');
    const [displayname, setDisplayname] = useState('');
    const [instagram, setInstagram] = useState('');
    const [facebook, setFacebook] = useState('');
    const [website, setWebsite] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const [profile, setProfile] = useState(null);
    //const [error, setError] = useState(null);
  
    const fetchProfile = useCallback(async () => {
        try {
          const loadingProfile = await getProfileByUsername(id);
          console.log(loadingProfile);
          setProfile(loadingProfile);
          
          setBio(loadingProfile.bio);
          setLocation(loadingProfile.location);
          setDisplayname(loadingProfile.displayname);
          setInstagram(loadingProfile.social.instagram);
          setFacebook(loadingProfile.social.facebook);
          setWebsite(loadingProfile.social.website);
        } catch (err) {
          //setError(err.message);
        }
    }, [id]);

    useEffect(() => {
      fetchProfile();
    }, [fetchProfile]);

    const onSubmit = async (e) => {
        e.preventDefault()
        let newFields = {}
        newFields["social"] = {};
        if(displayname !== profile.displayname)
            newFields["displayname"] = displayname;
        if(bio !== profile.bio)
            newFields["bio"] = bio;
        if(location !== profile.location)
            newFields["location"] = location;


        if (profile.social) {
            if (instagram !== profile.social.instagram) newFields["social"]["instagram"] = instagram;
            if (facebook !== profile.social.facebook) newFields["social"]["facebook"] = facebook;
            if (website !== profile.social.website) newFields["social"]["website"] = website;
        } else {
            // Initialize social object if not already present
            newFields["social"]["instagram"] = instagram;
            newFields["social"]["facebook"] = facebook;
            newFields["social"]["website"] = website;
        }
        updateProfile(currentUser.uid, newFields);
        await fetchProfile();
        //setSubmitted(true);
        setTimeout(() => { setSubmitted(true); }, 1000);
    }

    return <div className="page-content">
        {/*Get out of here! You're not supposed to be here!*/}
        {submitted && props.edit && <Navigate to={"/u/" + id} />}
        {!currentUser && props.edit && <Navigate to={"/u/" + id} />}
        {profile && props.edit && profile.uid !== currentUser.uid && <Navigate to={"/u/" + id} />}

        {props.edit && <>
            <h1>Editing your profile</h1>
            {props.edit && <a href={"/u/" + id}>Back to profile</a>}
            <>
                <div className="profile-edit">
                    <form onSubmit={onSubmit}>
                        <p><b>Display Name</b></p>
                        <input
                            type="text"
                            value={displayname} onChange={(e) => { setDisplayname(e.target.value) }}
                        />
                        <p><b>Bio</b></p>
                        {/*<textarea
                            type="text"
                            value={bio} onChange={(e) => { setBio(e.target.value) }}
                        />*/}
                        <EditorProvider>
                            <Toolbar>
                                <BtnUndo />
                                <BtnRedo />
                                <Separator />
                                <BtnBold />
                                <BtnItalic />
                                <BtnUnderline />
                                <BtnStrikeThrough />
                                <Separator />
                                <BtnNumberedList />
                                <BtnBulletList />
                                <Separator />
                                <BtnLink />
                                <BtnClearFormatting />
                                <HtmlButton />
                                <Separator />
                                <BtnStyles />
                            </Toolbar>
                            <Editor value={bio} onChange={(e) => { setBio(e.target.value )}} />
                        </EditorProvider>
                        <p><b>Location</b></p>
                        <input
                            type="text"
                            value={location} onChange={(e) => { setLocation(e.target.value) }}
                        />
                        <p><b>Instagram</b></p>
                        <input
                            type="text"
                            value={instagram} onChange={(e) => { setInstagram(e.target.value) }}
                        />
                        <p><b>Facebook</b></p>
                        <input
                            type="text"
                            value={facebook} onChange={(e) => { setFacebook(e.target.value) }}
                        />
                        <p><b>Website</b></p>
                        <input
                            type="text"
                            value={website} onChange={(e) => { setWebsite(e.target.value) }}
                        />
                        <br />
                        <br />
                        <input type="submit" />
                    </form>
                </div>
            </>
        </>}

        
        {!props.edit && <>
            <center>
                {profile && profile.displayname && <><h1 style={{margin: "0px"}}><u>{profile.displayname}</u></h1></>}
                {profile && profile.username && <><p style={{margin: "0px"}}><i>{"/u/" + profile.username}</i></p></>}
                {profile && profile.social && profile.social.instagram && <a href={"https://instagram.com/" + profile.social.instagram}>Instagram</a>}{" "}
                {profile && profile.social && profile.social.facebook && <a href={"https://facebook.com/" + profile.social.facebook}>Facebook</a>}{" "}
                {profile && profile.social && profile.social.website && <a href={profile.social.website}>Website</a>}
            </center>
            {currentUser && profile && currentUser.uid === profile.uid && !props.edit && <a href={"/u/" + id + "/edit"}>Edit your page?</a>}

            {profile && profile.bio && <div className="bio-container" dangerouslySetInnerHTML={{ __html: bio }} />}
            {profile && profile.location && <><p><b>Location</b></p><p>{profile.location}</p></>}
        </>}
    </div>
}

export default User;