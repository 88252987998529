import React, { useState, useEffect } from 'react';
import { getAPIURL } from '../network';

const Users = () => {
    const [content, setContent] = useState('');
  
    useEffect(() => {
        async function fetchContent() {
          try {
            const response = await fetch(`${getAPIURL()}/get/getAllUsers`);
            let text = await response.json();
            console.log(text)
            setContent(text);
          } catch (error) {
            console.error('Failed to fetch content:', error);
          }
        }
        fetchContent()
      }, []);

      
  
    return (
      <div className='page-content'>
        <h2>Users Directory</h2>
        <p>{content && content.map((user) => <p><a href={"/u/" + user.username}>{user.displayname + "(/u/" + user.username + ")"}</a></p>)}</p>
      </div>
    );
};

export default Users;
