import { getAuth } from "firebase/auth";
import { getAPIURL } from "../network";

function getProfile() {
    const { currentUser } = getAuth();
    return currentUser;
}

//const apiURL = "http://localhost:3001";
let apiURL = getAPIURL();

const getProfileByUsername = async (username) => {
    //const idToken = await getIdToken();
  
    //if (idToken) {
      try {
        const response = await fetch(`${apiURL}/post/getPublicUserData`, {
          method: 'POST',
          headers: {
            /*'Authorization': `Bearer ${idToken}`,  // Include the token in the request*/
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username: username })
        });
  
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error:', error);
        return null;
      }
    //}
};

const getProfileByUID = async (uid) => {
    //const idToken = await getIdToken();
  
    //if (idToken) {
      try {
        const response = await fetch(`${apiURL}/post/getPublicUserData`, {
          method: 'POST',
          headers: {
            /*'Authorization': `Bearer ${idToken}`,  // Include the token in the request*/
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid: uid })
        });
  
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error:', error);
        return null;
      }
    //}
};

const updateField = async (uid, field, value) => {

};

const getIdToken = async () => {
    const user = getProfile();
    if (user) {
      const idToken = await user.getIdToken();  // Get Firebase ID token
        return idToken;
    }
    return null;
};

const getAllUsers = async () => {
    const idToken = await getIdToken();
  
    if (idToken) {
      try {
        const response = await fetch(`${apiURL}/get/getAllUsers`, {
          method: 'GET'
        });
  
        const data = await response.json();
        return data
      } catch (error) {
        console.error('Error:', error);
      }
    }
};

const updateProfile = async (uid, fields) => {
    const idToken = await getIdToken();
  
    if (idToken) {
      try {
        const response = await fetch(`${apiURL}/post/updateUserData`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${idToken}`,  // Include the token in the request
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({uid: uid, fields: fields})
        });
  
        const data = await response.json();
        console.log('Authenticated Data:', data);
      } catch (error) {
        console.error('Error:', error);
      }
    }
};

export { getProfile, getProfileByUID, getProfileByUsername, updateField, getAllUsers, getIdToken, updateProfile }