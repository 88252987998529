import { useState } from 'react';
import '../../assets/stylesheets/Artists.css';
let artists = require('../../assets/data/artists.json');

function capStringLength(str, maxLength) {
  return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
}

function getListItem(key, value) {
  let description = "Info on this artist coming soon."
  let artistimage = <div className='list-thumbnail-container'><img alt={value.name + " Logo"} className='list-thumbnail' src="/artistinfo/default.png" /></div>;
  if(value.description !== "") {
    description = value.description;
  }
  if(value.artistimage) {
    artistimage = <div className='list-thumbnail-container'><img alt={value.name + " Logo"} className='list-thumbnail' src={"/artistinfo/" + key + "/" + value.artistimage} /></div>
  }
  return (
    <div className='list-item artist-list-item' key={key}>
      {artistimage}
      <div className='artist-list-content'>
        <h2 className='list-header'>{/*value.verified ? "✓ " : "  "*/}<a href={"/artists/" + key}>{value.name}</a></h2> {/* Access the name property */}
        
        <p>{capStringLength(description, 175)}{description.length > 175 ? <a href={"/artists/" + key}>Read More</a> : ""}</p>
      </div>
    </div>
  );
}

function Artists() {

  const [searchQuery, setSearchQuery] = useState("");
  
  return (
    <div className="page-content">
      <h1>This page is under construction...</h1>
      <p>That being said, here's a running roster of the folks who we know of. If your Artist/Band isn't here and you'd like it to be, <a href='/contactus'>please contact us</a> and we'll put ya up ;D Also, please note that some of these are not filled out as of yet, as folks are still sending information in :)</p>
      <center>
        <span>Search Artists </span><input
          type="text"
          placeholder="Search artists..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-bar"
        />
      </center>
      
      {Object.entries(artists)
        .filter(([key, value]) => 
          value.verified && 
          value.name.toLowerCase().includes(searchQuery.toLowerCase()) // Filter by search query
        )
        .sort(([keyA, valueA], [keyB, valueB]) => valueA.name.localeCompare(valueB.name)) // Sort alphabetically
        .map(([key, value]) => getListItem(key, value))}
      {/*Object.entries(artists)
        .filter(([key, value]) => value.verified == false)  // Only include verified artists
        .sort(([keyA, valueA], [keyB, valueB]) => {
          // Sort alphabetically by name
          return valueA.name.localeCompare(valueB.name);
        })
        .map(([key, value]) => getListItem(key, value))
      */}
    </div>
  );
}

export default Artists;
