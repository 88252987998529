function ContactUs() {
  return (
    <div className="page-content">
      <h1>Contact Us!</h1>
      <p>This is the part where I tell you that we are living in the 21st Century. We aren't as likely to see your message quickly on traditional email, but we do check our <a href='https://instagram.com/912Jams'><h1>INSTAGRAM</h1></a> very regularly.</p>
    </div>
  );
}

export default ContactUs;
