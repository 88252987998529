import { useParams } from 'react-router-dom';
import '../../assets/stylesheets/Artists.css';
let artists = require('../../assets/data/artists.json');

let ArtistCard = (props) => {
  return <div className='artist-card'>
    {props.name && <p><b>{props.name}</b></p>}
    {props.image && <img src={props.image} alt={props.name} />}
    {props.part && <p>{props.part}</p>}
    {props.description && <p>{props.description}</p>}
    {props.website && <p><a href={props.website}>Website</a></p>}
    {props.instagram && <p><a href={"https://instagram.com/" + props.instagram}>Instagram</a></p>}
    {props.facebook && <p><a href={props.facebook}>Facebook</a></p>}
  </div>
}

function SpecificArtists() {
  const { id } = useParams();
  let artist = artists[id];

  if(artist === undefined) {
    return <div className="page-content">
      <center>
        <h1>We don't know this artist...</h1>
        <p>If they should be on the site, let them know :D</p>
        <p>Anyone can have a page, just <a href='/contactus'>Contact us</a>!</p>
      </center>
    </div>;
  }
  if(!artist.verified && id !== "exampleband") {
    return <div className="page-content">
        <center>
          <h1><u>{artist.name}</u></h1>
          <h2>This artist is known to us, but they don't have a page!</h2>
          <p>Maybe reach out to them and let them know?</p>
          <p>Anyone can have a page, just <a href='/contactus'>Contact us</a>!</p>
        </center>
      </div>
  }

  let imagestr = <img alt={artist.name + " Logo"} className="artist-logo" src={"/artistinfo/default.png"}></img>
  let spotifystr = "";
  if(artist.spotifyembed) {
    spotifystr = <iframe title="Spotify" style={{'borderRadius': '12px'}} src={artist.spotifyembed} width="100%" height="200" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
  }
  if(artist.artistimage) {
    imagestr = <img alt={artist.name + " Logo"} className="artist-logo" src={"/artistinfo/" + id + "/" + artist.artistimage}></img>
  }
  return (
    <div className="page-content">
        <center>
            <h1><u>{artist.name}</u></h1>
            {imagestr}
            {artist.website ? <p>🌐 <a href={artist.website}>Website</a></p> : ""}
            {artist.youtube ? <p>▷ <a href={artist.youtube}>YouTube</a></p> : ""}
            {artist.instagram ? <p>📷 <a href={artist.instagram}>Instagram</a></p> : ""}
            <p>{artist.description === "" ? "No description... yet..." : artist.description}</p>
            {spotifystr && <><hr/><br />{spotifystr}</>}
            {artist.members && (<>
              <hr />
              <h2>Members</h2>
              <div className='members-section'>
                {artist.members.map((member) => (
                  <ArtistCard name={member.name} image={"/artistinfo/" + id + "/memberphotos/" + member.img} part={member.part} description={member.description} website={member.website} instagram={member.instagram} facebook={member.facebook}/>
                ))}
              </div>
            </>)}
        </center>
    </div>
  );
}

export default SpecificArtists;
