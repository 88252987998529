import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

//authDomain: "jams-bb42f.firebaseapp.com",

const firebaseConfig = {
    apiKey: "AIzaSyDuN7mNH8YpOLImF8-ZvV2DTF6gL9StEvE",
    authDomain: "jams-bb42f.firebaseapp.com",
    projectId: "jams-bb42f",
    storageBucket: "jams-bb42f.firebasestorage.app",
    messagingSenderId: "630979831121",
    appId: "1:630979831121:web:b7562cbc7a23b0c2f71f70",
    measurementId: "G-KE95JN4SB7"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app);



export { app, auth, db };