function FourOhFour() {
  return (
    <div className="App">
      <header className="App-header">
        <center>
        <h1>... uhhhh</h1>
        <h2>Sorry, but this page can't be found...</h2>
        <a href='/'>Go home?</a>
        </center>
      </header>
    </div>
  );
}

export default FourOhFour;
