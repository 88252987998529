import { auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile
} from "firebase/auth";
import { getIdToken } from "./Profile";
import { getAPIURL } from "../network";

export const doCreateUserWithEmailAndPassword = async (email, password, displayName, username) => {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    const idToken = await getIdToken();
    await updateProfile(result.user, { displayName }); // Correct usage of updateProfile

    fetch(`${getAPIURL()}/post/registerData`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        uid: result.user.uid,
        username: username,
        displayname: displayName,
        createdAt: new Date(),
        email: email,
        location: "",
        bio: ""
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    //const userDocRef = doc(db, "users", result.user.uid);
    //await setDoc(userDocRef, );
    return result.user;
};

export const doSignInWithEmailAndPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const user = result.user;
  console.log(user)

  // add user to firestore
};

export const doSignOut = () => {
  return auth.signOut();
};

export const doPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const doPasswordChange = (password) => {
  return updatePassword(auth.currentUser, password);
};

export const doSendEmailVerification = () => {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/home`,
  });
};