import React, { useEffect, useState } from 'react';
import { getAPIURL } from '../network';
let venues = require('../assets/data/venues.json');
const cheerio = require('cheerio');

const ExtractedContent = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    async function fetchContent() {
      try {
        const response = await fetch(getAPIURL());
        let text = await response.json();
        //text = text.replaceAll("<h3>", "</div><div class='list-item'><h2><u>").replaceAll("</h3>", "</u></h2>");

        Object.entries(venues)
          /*.filter(([key, value]) => 
            value.verified && 
            value.name.toLowerCase().includes(searchQuery.toLowerCase()) // Filter by search query
          )
          .sort(([keyA, valueA], [keyB, valueB]) => valueA.name.localeCompare(valueB.name)) // Sort alphabetically*/
          .forEach(([key, value]) => {
            console.log(key)
            
            value.foundas.forEach(element => {
              console.log(element)
              text = text.replaceAll(element, "<a href='/venues/" + key + "'>" + value.iwtbname + "</a>")
            })
          })

        //console.log(text)
        setContent(text);
      } catch (error) {
        console.error('Failed to fetch content:', error);
      }
    }

    fetchContent();
  }, []);

  return (
    <div>
      {content ? <div dangerouslySetInnerHTML={{ __html: content }} /> : <p>Loading...</p>}
    </div>
  );
};

export default ExtractedContent;
