import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from '../authContext';
import { getProfileByUID } from '../../firebase/Profile';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const { currentUser } = useAuth(); // Get the logged-in user from your AuthContext
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser && currentUser.uid) {
        try {
          // Get the user document from Firestore using the uid
          setUserData(await getProfileByUID(currentUser.uid))
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
      setLoading(false);
    };

    if (currentUser && currentUser.uid) {
      fetchUserData();
    } else {
      setUserData(null); // Clear user data when logged out
      setLoading(false);
    }
  }, [currentUser]);

  return (
    <UserContext.Provider value={{ userData, loading }}>
      {children}
    </UserContext.Provider>
  );
};
