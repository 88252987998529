import '../assets/App.css';
import '../assets/stylesheets/Home.css';
import welcome from '../assets/pagecontent/welcome.png';
import areyouanartist from '../assets/pagecontent/areyouanartist.png';
import { InstagramEmbed } from 'react-social-media-embed';

function Home() {
  return (
    <div className="page-content">
      <img alt='Welcome to 912Jams!' className='homepage-welcome' src={welcome} />
      <p>Hi! We are 912Jams. A collective of folks passionate about the local music scene of the Southeast. This, along with our <a href="/contactus">social media channels</a> serves as not only an archive of what's happening in local music, but also a who's who and a what's what of what's going on, as well as where it's going on. I know, I know... a mouthful. I had to read it again as well... Feel free to check out the other parts of the site!</p>
      <img alt='Are you an artist?' className='homepage-welcome' src={areyouanartist} />
      <p>This whole project depends on artists. Without artists there would be no music, no bands, no scene.</p>
      <p>No scene = no 912Jams = no fun</p>
      <p>If you are an artist or know someone who is, <a href='/contactus'>contact us</a> and we can get you on the site. The ideal outcome is that we get all of the local artists in one place for a deep dive on who's who and where you can find live music.</p>

      <center>
        <InstagramEmbed url='https://www.instagram.com/p/DBjYOfQu4wf/' width="50%"/>
      </center>
      
      <img alt='Are you an artist?' className='homepage-welcome' src={areyouanartist} />
      <img alt='Are you an artist?' className='homepage-welcome' src={areyouanartist} />
      <img alt='Are you an artist?' className='homepage-welcome' src={areyouanartist} />
      <img alt='Are you an artist?' className='homepage-welcome' src={areyouanartist} />
      <img alt='Are you an artist?' className='homepage-welcome' src={areyouanartist} />
      <img alt='Are you an artist?' className='homepage-welcome' src={areyouanartist} />
      <img alt='Are you an artist?' className='homepage-welcome' src={areyouanartist} />
      <img alt='Are you an artist?' className='homepage-welcome' src={areyouanartist} />
      <img alt='Are you an artist?' className='homepage-welcome' src={areyouanartist} />
      <img alt='Are you an artist?' className='homepage-welcome' src={areyouanartist} />
      <img alt='Are you an artist?' className='homepage-welcome' src={areyouanartist} />
      <img alt='Are you an artist?' className='homepage-welcome' src={areyouanartist} />
      <p>Originally, the "are you an artist" spam thing was just a test to see if I got the page spacing right... now it seems like... art? aaaaanyways, you scrolled all the way down here so have a cookie 🍪</p>
    </div>
  );
}

export default Home;
