import React, { useState } from 'react'
import { Navigate, Link } from 'react-router-dom'
import { useAuth } from '../../contexts/authContext'
import { doCreateUserWithEmailAndPassword } from '../../firebase/auth'

const Register = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [displayName, setDisplayName] = useState('');
    const [username, setUsername] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('')
    const [isRegistering, setIsRegistering] = useState(false)
    //const [errorMessage, setErrorMessage] = useState('')

    const { userLoggedIn } = useAuth()

    const onSubmit = async (e) => {
        e.preventDefault()
        if(!isRegistering) {
            setIsRegistering(true)
            await doCreateUserWithEmailAndPassword(email, password, displayName, username)
        }
    }

    return (
        <div className="page-content">
            <center>
            {userLoggedIn && (<Navigate to={'/'} replace={true} />)}

            <h3>Create a New Account</h3>

            <form
                onSubmit={onSubmit}
            >
                <div>
                    <label>
                        Display Name
                    </label>
                    <input
                        type="text"
                        autoComplete='given-name'
                        required
                        value={displayName} onChange={(e) => { setDisplayName(e.target.value) }}
                    />
                </div>

                <div>
                    <label>
                        Username
                    </label>
                    <input
                        type="text"
                        required
                        value={username} onChange={(e) => { setUsername(e.target.value) }}
                    />
                </div>

                <div>
                    <label>
                        Email
                    </label>
                    <input
                        type="email"
                        autoComplete='email'
                        required
                        value={email} onChange={(e) => { setEmail(e.target.value) }}
                    />
                </div>

                <div>
                    <label>
                        Password
                    </label>
                    <input
                        disabled={isRegistering}
                        type="password"
                        autoComplete='new-password'
                        required
                        value={password} onChange={(e) => { setPassword(e.target.value) }}
                    />
                </div>

                <div>
                    <label>
                        Confirm Password
                    </label>
                    <input
                        disabled={isRegistering}
                        type="password"
                        autoComplete='off'
                        required
                        value={confirmPassword} onChange={(e) => { setconfirmPassword(e.target.value) }}
                    />
                </div>

                {/*errorMessage && (
                    <p>{errorMessage}</p>
                )*/}

                <button
                    type="submit"
                    disabled={isRegistering}
                >
                    {isRegistering ? 'Signing Up...' : 'Sign Up'}
                </button>
                <div>
                    <p>Already have an account? {'   '}
                    <Link to={'/login'}>Login</Link></p>
                </div>
            </form>
            </center>
        </div>
    )
}

export default Register